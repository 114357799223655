/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import 'core-js/es6';
import 'core-js/es7/reflect';
import { environment } from './environments/environment';
require('zone.js/dist/zone');

if (environment.production) {
  // Production
} else {
  // Development and test
  Error['stackTraceLimit'] = Infinity;
  require('zone.js/dist/long-stack-trace-zone');
}

(window as any).global = window;
