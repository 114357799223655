/**
 * The file contents for the current environment will overwrite these during
 * build. The build system defaults to the dev environment which uses
 * `environment.ts`, but if you do `ng build --env=prod` then
 * `environment.prod.ts` will be used instead. The list of which env maps to
 * which file can be found in `angular-cli.json`. `.env.ts` is generated by the
 * `npm run env` command import env from './.env';
 */

/**
 * @ignore
 */
let production = 'true';
let serverUrl = 'https://ruckit-platform-staging-02-f0121c02ce3a.herokuapp.com/api/';
let imageServerUrl = 'http://ruckit-platform.s3-website-us-east-1.amazonaws.com';
let resizeImageServerUrl = 'https://d8ojjhfh4a4yx.cloudfront.net';
let errorTrackingEnv = 'production';
let googleMapsKey = 'AIzaSyBnyQsblAiJeVVAHKQyo76Nx8PVp-N2mLU';
let applicationVersion = 'b8e88c8f791e6d0a1419d2c4bc459e94f52c97b2';
let scaleitUrl = 'https://api.scaleitusa.com/api/ruckit/v1/';
let s3ServerUrl = 'https://s3.amazonaws.com/ruckit-platform/';
let ticketManagerUrl = 'https://tickets.goruckit.com';
let ordersUrl = 'https://orders.goruckit.com';

export const environment = {
  production: production === 'true',
  serverUrl: serverUrl,
  imageServerUrl: imageServerUrl,
  resizeImageServerUrl: resizeImageServerUrl,
  s3ServerUrl: s3ServerUrl,
  scaleitUrl: scaleitUrl,
  ticketManagerUrl: ticketManagerUrl,
  ordersUrl: ordersUrl,
  errorTrackingEnv: errorTrackingEnv,
  applicationVersion: applicationVersion,
  defaultLanguage: 'en-US',
  supportedLanguages: [
    'en-US',
    'fr-FR'
  ],
  googleMapsKey: googleMapsKey
};
